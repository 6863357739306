const transform = (input) =>
  input
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

// Helper function to parse errors
export const parseErrorsWithFieldNames = (errors) => {
  const messages = [];

  for (const key in errors) {
    if (Array.isArray(errors[key])) {
      // Field-specific errors (e.g., { username: ["This field is required."] })
      errors[key].forEach((message) => {
        messages.push({ field: transform(key), message });
      });
    } else if (typeof errors[key] === "object") {
      // Nested errors (e.g., { user: { email: ["Invalid email"] } })
      const nestedMessages = parseErrorsWithFieldNames(errors[key]);
      nestedMessages.forEach((nestedError) => {
        messages.push({
          field: `${key}.${nestedError?.field}`,
          message: nestedError?.message,
        });
      });
    } else if (typeof errors[key] === "string") {
      // String errors (less common)
      messages.push({ field: key, message: errors[key] });
    }
  }

  return messages;
};

// Common error handler function
export const handleApiErrors = (error) => {
  let errorMessages = [{ field: null, message: "An unknown error occurred." }];

  if (error.response) {
    // Error response from the server
    const { status, data } = error.response;

    if (data) {
      if (data?.detail || data?.message) {
        // Handles { detail: "Error message" }
        errorMessages = [
          { field: null, message: data?.detail || data?.message },
        ];
      } else if (
        data?.non_field_errors &&
        Array.isArray(data?.non_field_errors)
      ) {
        // Handles { non_field_errors: ["Error message"] }
        errorMessages = data?.non_field_errors.map((message) => ({
          field: null,
          message,
        }));
      } else if (Array.isArray(data)) {
        // Handles Error of type array
        errorMessages = data?.map((message) => ({ field: null, message }));
      } else if (typeof data === "object") {
        // Handles field-specific or nested errors
        errorMessages = parseErrorsWithFieldNames(data);
      }
    } else {
      // If no data is returned but there's a status code
      if (status === 403) {
        errorMessages = [
          { field: null, message: "Forbidden: Access is denied." },
        ];
      } else if (status === 500) {
        errorMessages = [
          {
            field: null,
            message: "Internal Server Error. Please try again later.",
          },
        ];
      } else {
        errorMessages = [
          {
            field: null,
            message: `Unexpected error occurred. Status code: ${status}`,
          },
        ];
      }
    }
  } else if (error?.request) {
    // Network errors or no response received
    errorMessages = [
      {
        field: null,
        message: "No response from the server. Please check your connection.",
      },
    ];
  } else if (error?.message) {
    // JavaScript Error object
    errorMessages = [{ field: null, message: error?.message }];
  } else if (typeof error === "string") {
    // Non-object errors
    errorMessages = [{ field: null, message: error }];
  }

  return errorMessages;
};
